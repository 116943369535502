import React from 'react';
import { useLocation } from 'react-router';
import products from '../../assets/product.json'

export default function ProductDetails() {
    const location=useLocation().pathname.split('/')[3]
    // const [productID,setProductID]=useState("")
    const product = products.products.map((element)=>{
        if(element.id==location) {
            return element
        }
    })
    console.log(product)
    return (
        <div className='container-flex' id="product_view_container">
            <div className="container" >
                <div className="row" id="product_view">
                    <div className="col" id="product_view_image">
                        <img src={product[location-1].img} alt="product image" height="300px" width="300px"/>
                    </div>
                    <div className="col" id="product_view_details">
                        <h3>{product[location-1].name}</h3>
                        <ul>
                            <li>{product[location-1].desc}</li>
                            <li>Color: {product[location-1].color}</li>
                        </ul>
                    </div>
                    {/* <div className="w-100"></div> */}
                </div>
            </div>
        </div>
    );
}
