import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from './components/Home';
import NavBar from './components/NavBar';
import './assets/css/background.css'
import './assets/css/productpage.css'
import Footer from './components/footer';
import ProductDetails from './components/product/ProductDetails';
import ViewAllProducts from './components/product/ViewAllProducts';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/product" element={<ViewAllProducts />} />
          <Route path="/product/view/*" element={<ProductDetails />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App;
