import React from 'react'

export default function footer() {
  return (
    <scetion id="contact">
      <footer className="bg-dark text-center text-white">
        <div className="container p-4">
          <section className="mb-4">
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-google"></i>
            </a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-instagram"></i>
            </a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
              <i className="fab fa-github"></i>
            </a>
          </section>

          <section className="">
            <form action="">
              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <p className="pt-2">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div>
                <div className="col-md-5 col-12">
                  <div className="form-outline form-white mb-4">
                    <input type="email" id="form5Example2" className="form-control" />
                    <label className="form-label" for="form5Example2">Email address</label>
                  </div>
                </div>
                <div className="col-auto">
                  <button type="submit" className="btn btn-outline-light mb-4">
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </section>

          <section className="">
            <div className="row">
              {/* <!-- Repeat the following block for each set of links --> */}
              <div className="col-lg col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Contact Details</h5>
                <ul className="list-unstyled mb-1">
                  <li>
                    <p className="text-white">Khandelwal
                      Compound, Palda
                      Indore
                      Madhya Pradesh ,
                      452001</p>
                  </li>
                  <li>
                    <a href="mailto:contact@prefabxpressb.com" className="text-white">contact@prefabxpressb.com</a>
                  </li>

                  <li>
                    <a href="#!" className="text-white">+91 6260736035</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">+91 7987661639</a>
                  </li>
                </ul>
              </div>
              {/* <!-- End of block --> */}
            </div>
          </section>
        </div>

        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2024 Copyright:
          <a className="text-white" href="https://prefabxpressb.com/"> prefabxpressb.com</a>
        </div>
      </footer>
    </scetion>
  )
}
