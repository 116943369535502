import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Cards(props) {
    const [cardRem, setCardRem] = useState({ width: "18rem" })
    const handleRem = ()=>{
        if(window.innerWidth>=780 && window.innerWidth<=990){
            setCardRem({
                ...cardRem,
                width: "12rem",
            })
        }
        else{
            setCardRem({
                ...cardRem,
                width: "18rem",
            })
        }
    }
    useEffect(() => {
        if(window.innerWidth>=780 && window.innerWidth<=990){
            setCardRem({
                ...cardRem,
                width: "12rem",
            })
        }
        else{
            setCardRem({
                ...cardRem,
                width: "18rem",
            })
        }
        // // Add event listener on component mount
        // window.addEventListener('resize', handleRem);

        // // Cleanup the event listener on component unmount
        // return () => {
        //     window.removeEventListener('resize', handleRem);
        // };
    }, [])


    return (
        <div className="card d-inline-block w-auto" id="card" style={cardRem}>
            <Link to={`/product/view/${props.id}`}><img className="card-img-top" src={props.img} alt="product image" height="300px" /></Link>
            <div className="card-body w-auto">
                <h5 className="card-title">{props.name}</h5>
                <p className="card-text">Color: {props.color}</p>
                <Link to={`/product/view/${props.id}`} className="btn btn-primary">View Details</Link>
            </div>
        </div>
    )
}
