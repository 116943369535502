import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
  const [logo, setLogo] = useState("")
  useEffect(() => {
    const getLogo = async () => {
      let newimg = await fetch("https://res.cloudinary.com/duj2m3mpi/image/upload/v1706337686/prefabxpressb/Screenshot_from_2024-01-27_12-03-58_rjf4yn.png")
      // console.log(newimg)
      setLogo(newimg.url)
    }
    getLogo()
  }, [])

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light text-center" style={{ position: "fixed" }}>
      <Link className="navbar-brand" to="/"><img src={logo} height="50px" width="200px" alt="LOGO" /></Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/product">Products</Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">Contact Us</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}