import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cards from './Cards';
import products from '../assets/product.json'

export default function Home() {
  const [img1, setImg1] = useState("")
  // Load all products
  const productsData = products.products

  // Product Slider
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 1000, // Adjust the speed of the slide transition
    slidesToShow: 3, // Adjust the number of slides visible at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust the time between automatic slides (in milliseconds)
  })

  // load carsoul
  useEffect(() => {
    const getCarsoule = async () => {
      let newimg = await fetch("https://res.cloudinary.com/duj2m3mpi/image/upload/v1706338705/prefabxpressb/WhatsApp_Image_2024-01-14_at_11.12.06_PM_qaw8nx.jpg")
      setImg1(newimg.url)
    }
    getCarsoule()
  }, [])

  const handleResize = () => {
    if (window.innerWidth <= 800) {
      setSettings({
        ...settings,
        slidesToShow: 1
      })
    }
    else {
      setSettings({
        ...settings,
        slidesToShow: 3
      })
    }
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src={img1} alt="First slide" height="500px" />
          </div>
          {/* <div className="carousel-item">
            <img className="d-block w-100" src={img1} alt="Second slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={img1} alt="Third slide" />
          </div> */}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>

      </div>
      <div className="container-flex">
        <div className="container">
          <div className="row">
            <div className="col" id="home_aboutus">We provide PUF Sandwich Panels, Clean Room Doors, Cold Room Doors, and Air Curtains, offering a comprehensive selection for various applications. Our products ensure quality and efficiency in diverse industrial environments. Explore our range to find tailored solutions for your needs.</div>
            {/* <div className="col">Column</div> */}
            <div className="w-100"></div>
            <div className="col" id="home_read_more">
              <button type="button" className="btn btn-primary">Read More</button>
            </div>
            <div className="w-100"></div>
            <div className="w-100"></div>
            <div className="col">
              <h3>Our Products</h3>
              <hr width="200px" align="left" />
            </div>
            <div className="w-100"></div>
            <div className="container w-100" id="home_product_list">
              <Slider {...settings} className="container">
                {productsData.map((element) => (
                  <div key={element.id} className="zoom text-center">
                    <Cards name={element.name} color={element.color} img={element.img} id={element.id} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="w-100"></div>
            <div className="col w-100 text-center" id="home_view_all_products">
              <button type="button" className="btn btn-secondary">View All Products</button>
            </div>
            <div className="w-100"></div>
            <div className="col" id="product_information1">
              As Prefabxpress B, we excel in delivering premium solutions, featuring PUF Sandwich Panels, Clean Room Doors, Cold Room Doors, and Air Curtains, thoughtfully crafted to address varied industrial needs. Our unwavering dedication to quality is underscored by the commendations of contented clients who have witnessed the reliability and effectiveness of our products. Discover our offerings for customized solutions that consistently surpass industry benchmarks.

            </div>
            <div className="col" id="product_information2">
              Within our product portfolio, we showcase the impressive "Insulated Roof Panel." This product boasts versatility with thickness options ranging from 30mm to 120mm, offering tailored solutions for diverse project needs. Its striking red color not only provides effective insulation but also adds a visually appealing element to structures. Embodying a commitment to quality and innovation, our range of products caters to those who value both performance and aesthetic appeal. Join our satisfied clientele who appreciate the reliability and customization options available in our offerings.

            </div>
          </div>

        </div>
      </div>
      {/* <div className="container">
        <h3>Client Projects</h3>
        <hr width="200px" align="left" />
        <marquee behavior="scroll" direction="left">Here is some scrolling text... right to left!</marquee>
      </div> */}
    </>
  );
}
