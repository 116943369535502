import React from 'react';
import products from "../../assets/product.json";
import Cards from '../Cards';

export default function ViewAllProducts() {
    // Load all products
    const productsData = products.products;

    return (
        <div className="container-flex" id="view_all_products_layout">
            <div className='row'>
                {
                    productsData.map((element) => (
                        <div key={element.id} className='col-md-4 col-sm-1 h-auto text-center my-5'>
                            <Cards name={element.name} color={element.color} img={element.img} id={element.id} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
